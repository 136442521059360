import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';
import { truncate } from 'lodash-es';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

import Query from 'components/Explorer/models/query';
import ResearchOutput from 'components/Explorer/models/research_output';

class TopOutputs extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  state = {
    loading: true,
    loadingFailed: false,
    data: []
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Highlights-TopOutputs Highlights-block">
        <h2 className="Highlights-title">{I18n.t('Explorer.Highlights.TopOutputs.title')}</h2>

        <div className="Highlights-content-wrapper">
          <Transition classNames="Content" timeout={250}>
            {this.state.loading ? this.loadingMessage : this.content}
          </Transition>
          {this.hasResults && (
            <Link
              onClick={this.trackJumpLink}
              to={this.query.locationWithPathname('/outputs')}
              className="Highlights-jump-link"
            >
              {I18n.t('Explorer.Highlights.TopOutputs.more')}
            </Link>
          )}
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <div className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) {
      return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
    }

    if (!this.hasResults) {
      return <div className="Highlights-content-empty-message">{I18n.t('Explorer.Highlights.TopOutputs.empty')}</div>;
    }

    return (
      <ol>
        {this.state.data.map((output) => (
          <li key={output.id} className="Highlights-TopOutputs-Item">
            <div>
              <div className="Highlights-TopOutputs-Count">
                <strong>{output.total_mentions}</strong>
                mentions
              </div>
            </div>
            <div>
              <div className="Highlights-TopOutputs-Title">
                <a
                  href={`https://${window.site_config.externalDetailsPageSubdomain}.${window.site_config.altmetricDomain}/details/${output.id}`}
                  onClick={(event) => this.openDetailsPage(event, output)}
                  className="Highlights-TopOutputs-Link"
                >
                  {truncate(output.title, { length: 120, separator: /\W/ })}
                </a>
              </div>
              <span className="Highlights-TopOutputs-Meta">
                <span className="Highlights-TopOutputs-Score">
                  <em>{output.score}</em> Altmetric score
                </span>
                <cite>{output.meta}</cite>
              </span>
            </div>
          </li>
        ))}
      </ol>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsTopOutputs();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        data = response.body.map((r) => new ResearchOutput(r));
      }

      this.setState({ loading: false, loadingFailed, data });
    });
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  openDetailsPage = (event, output) => {
    event.preventDefault();
    Analytics.trackEvent('highlights-open-details-page', {
      outputId: output.id
    });
    this.props.history.push(this.query.withShowDetails(output.id).location);
  };

  get hasResults() {
    return this.state.data && this.state.data.length > 0;
  }

  trackJumpLink = () => {
    Analytics.trackEvent('highlights-click-jump-link', {
      jumpedTo: 'top-outputs'
    });
  };
}

export default withRouter(TopOutputs);
