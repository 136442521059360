import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import ResearchOutput from 'components/Explorer/models/research_output';
import Query from 'components/Explorer/models/query';

import Spinner from 'components/Explorer/Spinner';

class DetailPage extends React.Component {
  static propTypes = {
    output: PropTypes.instanceOf(ResearchOutput).isRequired,
    history: PropTypes.object
  };

  state = {
    loading: true
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.closeButtonRef = React.createRef();
    this.previouslyFocused = document.activeElement;
  }

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    this.externalElements = Array.from(document.querySelectorAll('[data-inertable]'));
    this.externalElements.forEach((element) => (element.inert = true));
    window.addEventListener('keydown', this.closeOnEscape);
    window.addEventListener('message', this.closeOnEscape);
    this.containerRef.current.focus();
  }

  componentWillUnmount() {
    this.query.cleanup();
    window.removeEventListener('keydown', this.closeOnEscape);
    window.removeEventListener('message', this.closeOnEscape);
  }

  render() {
    return (
      <div>
        {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
        <div className="DetailPage-overlay" onClick={this.onClose}></div>
        <div
          className="DetailPage"
          ref={this.containerRef}
          tabIndex="-1"
          role="dialog"
          aria-modal="true"
          aria-labelledby="detailpage-title"
        >
          <div className="DetailPage-header DetailPage-title">
            <div className="content" id="detailpage-title">
              {I18n.t('Explorer.DetailPage.title')}
            </div>

            <div className="buttons">
              <button ref={this.closeButtonRef} className="close" onClick={this.onClose}>
                {I18n.t('Explorer.DetailPage.buttons.close')}
              </button>
            </div>
          </div>
          <div className="DetailPage-content">
            {this.state.loading ? this.loadingMessage : null}
            {this.content}
          </div>
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <Spinner />;
  }

  get content() {
    const url = `https://${window.site_config.detailsPageSubdomain}.${window.site_config.altmetricDomain}/details/${this.props.output.id}`;

    return (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <iframe
        src={url}
        className={this.state.loading ? 'not-visible' : 'visible'}
        onLoad={this.displayIframe}
        data-hj-allow-iframe={true}
      />
    );
  }

  displayIframe = () => {
    this.setState({ loading: false });
  };

  closeOnEscape = (event) => {
    if (event.key === 'Escape' || event.data?.key === 'Escape') {
      this.closeButtonRef.current.click();
    }
  };

  onClose = () => {
    this.externalElements.forEach((element) => (element.inert = false));

    Analytics.trackEvent('close-detail-page', {
      outputId: this.props.output.id
    });

    this.props.history.push(this.query.withShowDetails(null).location);

    requestAnimationFrame(() => {
      this.previouslyFocused.focus();
    });
  };
}

export default withRouter(DetailPage);
